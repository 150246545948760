import { css } from '@emotion/css';
import { cloneDeep } from 'lodash';
import React,{useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';

import { GrafanaTheme2, locationUtil, textUtil } from '@grafana/data';
import { Dropdown, ToolbarButton, useStyles2 } from '@grafana/ui';
import { config } from 'app/core/config';
import { contextSrv } from 'app/core/core';
import { loadOrganization } from 'app/features/org/state/actions';
import { useDispatch, useSelector } from 'app/types';

import { enrichHelpItem } from '../MegaMenu/utils';
import { NewsContainer } from '../News/NewsContainer';
import { OrganizationSwitcher } from '../OrganizationSwitcher/OrganizationSwitcher';
import { QuickAdd } from '../QuickAdd/QuickAdd';
import { TOP_BAR_LEVEL_HEIGHT } from '../types';

import { SignInLink } from './SignInLink';
import { TopNavBarMenu } from './TopNavBarMenu';
import { TopSearchBarCommandPaletteTrigger } from './TopSearchBarCommandPaletteTrigger';
import { TopSearchBarSection } from './TopSearchBarSection';
import { getTheme } from '../../../../../../packages/grafana-ui/src/themes';

export const TopSearchBar = React.memo(function TopSearchBar() {
  const styles = useStyles2(getStyles);
  const navIndex = useSelector((state) => state.navIndex);
  const location = useLocation();
  const dispatch = useDispatch();
  const organizationSelector = useSelector((state) => state.organization.organization);

  const helpNode = cloneDeep(navIndex['help']);
  const enrichedHelpNode = helpNode ? enrichHelpItem(helpNode) : undefined;
  const profileNode = navIndex['profile'];

  const [transformedOrgName,setTransformedOrgName] = useState<string>("");

  useEffect(()=>{
    dispatch(loadOrganization());
  },[dispatch])

  useEffect(()=>{
    if(organizationSelector.name !== undefined){
      const fileName = "organization-logos/" + organizationSelector.name.toLowerCase().replace(/ /g,'-').replace(/[.]/g,'-') + '.' + styles.themeMode;
      
      // we are checking if the an image with this name exists in the img/organization-logos/ folder, 
      // if it doesn't we load a default image. 
      CheckForImage(fileName, (fullFilename) => {
          if(fullFilename){
            setTransformedOrgName(fullFilename);
          }else{
            // we didnt find a filename with the orgName, check for a default image;
            console.log('Organization logo for \'' + organizationSelector.name 
            + '\' not found! Please provide an image with this file name (jpg, png or svg) in the \'public/img/organization-logos/\' folder : \'' 
            + fileName + '\'');

            CheckForImage("organization-logos/default-logo", (defaultFilename) => {
              if(defaultFilename){
                setTransformedOrgName(defaultFilename);
              }else{
                console.log("Default logo not found! Please provide an image with name \'default-logo\' (jpg, png or svg) in the \'public/img/organization-logos/\'");
                setTransformedOrgName("grafana_icon.svg")
              }
            });
          }
      });
    }
  },[organizationSelector])

  let homeUrl = config.appSubUrl || '/';
  if (!config.bootData.user.isSignedIn && !config.anonymousEnabled) {
    homeUrl = textUtil.sanitizeUrl(locationUtil.getUrlForPartial(location, { forceLogin: 'true' }));
  }

  return (
    <div className={styles.layout}>
      <TopSearchBarSection>
        <a className={styles.logo} href={homeUrl} title="Go to home">
            <img className={styles.img} src={"public/img/"+transformedOrgName} alt="Logo" />
        </a>
        <OrganizationSwitcher /> 
      </TopSearchBarSection>

      <TopSearchBarSection>
        <TopSearchBarCommandPaletteTrigger />
      </TopSearchBarSection>

      <TopSearchBarSection align="right">
        <QuickAdd />
        {enrichedHelpNode && (
          <Dropdown overlay={() => <TopNavBarMenu node={enrichedHelpNode} />} placement="bottom-end">
            <ToolbarButton iconOnly icon="question-circle" aria-label="Help" />
          </Dropdown>
        )}
        {config.newsFeedEnabled && <NewsContainer />}
        {!contextSrv.user.isSignedIn && <SignInLink />}
        {profileNode && (
          <Dropdown overlay={() => <TopNavBarMenu node={profileNode} />} placement="bottom-end">
            <ToolbarButton
              className={styles.profileButton}
              imgSrc={contextSrv.user.gravatarUrl}
              imgAlt="User avatar"
              aria-label="Profile"
            />
          </Dropdown>
        )}
      </TopSearchBarSection>
    </div>
  );
});

const getStyles = (theme: GrafanaTheme2) => ({
  layout: css({
    height: TOP_BAR_LEVEL_HEIGHT,
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    padding: theme.spacing(0, 1, 0, 2),
    borderBottom: `1px solid ${theme.colors.border.weak}`,
    justifyContent: 'space-between',

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1.5fr minmax(240px, 1fr) 1.5fr', // search should not be smaller than 240px
      display: 'grid',

      justifyContent: 'flex-start',
    },
  }),
  img: css({
    height: theme.spacing(3.5),
   // width: theme.spacing(3),
  }),
  logo: css({
    display: 'flex',
  }),
  profileButton: css({
    padding: theme.spacing(0, 0.25),
    img: {
      borderRadius: theme.shape.radius.circle,
      height: '24px',
      marginRight: 0,
      width: '24px',
    },
  }),
  themeMode: theme.colors.mode,
  theme: theme
});

const CheckForImage = (fileName:string, resultCallback:(fileName:string) => void) => {
  let fullFilename = fileName + ".jpg";

  const img = new Image();
  img.src = 'public/img/' + fullFilename;

  if (img.complete) {
    resultCallback(fullFilename);
  } else {
    img.onload = () => {
      resultCallback(fullFilename);
    };

    img.onerror = (er) => {
      fullFilename = fileName + ".png";
      img.src = 'public/img/' + fullFilename;
      img.onerror = (er) => {
        fullFilename = fileName + ".svg";
        img.src = 'public/img/' + fullFilename;
        img.onerror = (er) => {
          resultCallback('');
        };
      };
    };
  }
}